var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        cancelText: _vm.$t("Cancel"),
        title: _vm.$t("Category setting"),
        "confirm-loading": _vm.loading
      },
      on: {
        cancel: function() {
          _vm.$emit("cancel")
        },
        ok: _vm.updateCodesCategory
      }
    },
    [
      _c("a-tree-select", {
        staticStyle: { width: "100%" },
        attrs: {
          "dropdown-style": { maxHeight: "400px", overflow: "auto" },
          "tree-data": _vm.categories,
          "tree-default-expand-all": ""
        },
        model: {
          value: _vm.category,
          callback: function($$v) {
            _vm.category = $$v
          },
          expression: "category"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }