//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  props: {
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      category: ''
    };
  },

  computed: {
    ...mapGetters('category', ['categories'])
  },

  methods: {
    changeCategory(value) {
      this.category = value;
    },
    updateCodesCategory() {
      this.$emit('ok', { category_id: this.category });
    }
  }
};
